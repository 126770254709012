<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Productos</h4>
            <div class="small text-muted">Administración general de los productos</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col md="9">
          <b-row>
            <b-col md="4">                         
              <Widget :configWidget="configWidget" 
                      reference="cant_products" 
                      :hasAccess="configWidget.elements.cantProducts" />

              <Widget :configWidget="configWidget" 
                      reference="pie_origen_marketplace_interested" 
                      :hasAccess="configWidget.elements.pieOrigenMarketplaceInterested"
                      v-if="parameters.haveMarketplace" />

              <Widget :configWidget="configWidget" 
                      reference="list_products_in_resvision" 
                      :hasAccess="configWidget.elements.listProductsInResvision" 
                      v-if="!parameters.haveMarketplace"/>                                                

              <Widget :configWidget="configWidget" 
                      reference="list_sublines" 
                      :hasAccess="configWidget.elements.listSublines" 
                      v-if="parameters.haveLines"/>                               

              <Widget :configWidget="configWidget" 
                      reference="list_subcategories" 
                      :hasAccess="configWidget.elements.listSubcategories" />                       
            </b-col>
            <b-col md="8" v-if="parameters.haveMarketplace">                         
              <Widget :configWidget="configWidget" 
                      reference="list_products_in_resvision" 
                      :hasAccess="configWidget.elements.listProductsInResvision" />            

              <Widget :configWidget="configWidget" 
                      reference="list_marketplace_message_send_interested" 
                      :hasAccess="configWidget.elements.listMarketplaceMessageSendInterested"/>               
            </b-col>

            <b-col md="8" v-if="!parameters.haveMarketplace">
              <Widget :configWidget="configWidget"
                      reference="list_products_with_stock_minimum"
                      :hasAccess="configWidget.elements.listProductsWithStockMinimum" />
              <Widget :configWidget="configWidget"
                      reference="list_products_without_stock"
                      :hasAccess="configWidget.elements.listProductsWithoutStock" />
            </b-col>            
          </b-row>
        </b-col>  
        <b-col md="3">        
          
          <b-list-group v-if="!parameters.haveMarketplace">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openProducts()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Productos</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de productos y todas sus variedades 
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group v-if="!parameters.haveMarketplace && isContents" class="mb-2">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openProductsWeb()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Productos en Sitio Web</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de productos para mostrar en el sitio web
              </p>
            </b-list-group-item>
          </b-list-group>           

          <b-list-group v-if="parameters.haveMarketplace">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openMarketplace()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Marketplace</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de productos de marketplace
              </p>
            </b-list-group-item>
          </b-list-group>   
          
          <b-list-group v-if="parameters.haveProductsCompound">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openProductsCompound()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Productos Compuestos</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de productos compuestos
              </p>
            </b-list-group-item>
          </b-list-group>                           
        
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCategories()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Categorias</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de categorias y sub-categorias
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group v-if="parameters.haveLines"> 
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openLines()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Lineas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de linea y sub-lineas
              </p>
            </b-list-group-item>
          </b-list-group>         

          <b-list-group v-if="parameters.haveColors">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openColors()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Colores</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de colores
              </p>
            </b-list-group-item>
          </b-list-group>            

          <b-list-group v-if="parameters.haveWaist">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openWaist()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Talles</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de talles
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group v-if="!parameters.haveMarketplace && parameters.haveUnitMeasurement">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openUnitMeasurement()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Unidades de Medidas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de unidades de medidas
              </p>
            </b-list-group-item>
          </b-list-group> 

          <b-list-group v-if="parameters.haveMarketplace">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openContactSold()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Productos Vendidos</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Listado de productos que terminaron en ventas
              </p>
            </b-list-group-item>
          </b-list-group>      

          <hr v-if="arr.PHPCustom.length">
          <b-list-group class="mt-2" v-if="arr.PHPCustom.length">
            <b-list-group-item v-for="item in arr.PHPCustom" :key="item.id" href="#" class="flex-column align-items-start" @click="openPHPCustom(item)">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{item.name}}</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                {{item.observations}}
              </p>
            </b-list-group-item>
          </b-list-group>               
        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import Param from '@/config/parameters'
  import ServicesPHP from '@/components/modules/users/php/staff/services'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.PRODUCTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },        
        configWidget: {
          module_id: Modules.PRODUCTOS,
          profile_id: Profiles.PERSONAL,
          elements: {             
            cantProducts: true,         
            pieOrigenMarketplaceInterested: true,
            listSublines: true,
            listSubcategories: true,
            listProductsInResvision: true,
            listMarketplaceMessageSendInterested: true,
            listProductsWithoutStock: true,
            listProductsWithStockMinimum: true,
          }
        },
        parameters: {
          haveLines: Helper.hasParametersAccess(Param.P7),
          haveColors: Helper.hasParametersAccess(Param.P8),
          haveWaist: Helper.hasParametersAccess(Param.P9),
          haveMarketplace: Helper.hasParametersAccess(Param.P18),
          haveProductsCompound: Helper.hasParametersAccess(Param.P30),
          haveUnitMeasurement: Helper.hasParametersAccess(Param.P31),
        },           
        primaryColor: '',
        arr: {
          PHPCustom: []
        },
        modulesActive: null,
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.cantProducts = Helper.hasAccessWidget(this.configWidget, 'cant_products')      
      this.configWidget.elements.pieOrigenMarketplaceInterested = Helper.hasAccessWidget(this.configWidget, 'pie_origen_marketplace_interested')      
      this.configWidget.elements.listSublines = Helper.hasAccessWidget(this.configWidget, 'list_sublines')      
      this.configWidget.elements.listSubcategories = Helper.hasAccessWidget(this.configWidget, 'list_subcategories')      
      this.configWidget.elements.listProductsInResvision = Helper.hasAccessWidget(this.configWidget, 'list_products_in_resvision')
      this.configWidget.elements.listMarketplaceMessageSendInterested = Helper.hasAccessWidget(this.configWidget, 'list_marketplace_message_send_interested')
      this.configWidget.elements.listProductsWithoutStock = Helper.hasAccessWidget(this.configWidget, 'list_products_without_stock')
      this.configWidget.elements.listProductsWithStockMinimum = Helper.hasAccessWidget(this.configWidget, 'list_products_with_stock_minimum')
      /* Fin configuracion */
    },
    mounted() {
      this.modulesActive = Session.getSession().auth.user.permissions.modules

      this.setup()
      this.getPHPCustom()
    }, 
    computed: {
      isCost() {
        var status = false
        if(this.modulesActive) {
          this.modulesActive.forEach(element => {
            if(element.id == Modules.COSTOS) {
              status = true
            }
          })          
        }
        return status
      },
      isContents() {
        var status = false
        if(this.modulesActive) {
          this.modulesActive.forEach(element => {
            if(element.id == Modules.CONTENIDOS) {
              status = true
            }
          })
        }
        return status
      },           
    },
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      
      openProducts() {
        this.$router.push({ name: 'ProductsStaffCrudProducts' })
      },
      
      openMarketplace() {
        this.$router.push({ name: 'ProductsStaffCrudProductsMarketplace' })
      },
      
      openProductsCompound() {
        this.$router.push({ name: 'ProductsStaffCrudProductsCompound' })
      },
        
      openLines() {
        this.$router.push({ name: 'ProductsStaffCrudLines' })
      },
      
      openCategories() {
        this.$router.push({ name: 'ProductsStaffCrudCategories' })
      },
      
      openColors() {
        this.$router.push({ name: 'ProductsStaffCrudColors' })
      },     

      openWaist() {
        this.$router.push({ name: 'ProductsStaffCrudWaist' })
      },

      openContactSold() {
        this.$router.push({ name: 'ProductsStaffSummaryContactSold' })
      },

      openUnitMeasurement() {
        this.$router.push({ name: 'ProductsStaffCrudUnitMeasurement' })
      },

      openProductsWeb() {
        this.$router.push({ name: 'ProductsStaffCrudProductsWeb'})
      },

      getPHPCustom() {
        ServicesPHP.obtenerPHPByModule(Modules.PRODUCTOS)
        .then((response) => {
          this.arr.PHPCustom = response.data       
        })
      },
      openPHPCustom(item) {
        this.$router.push({ name: 'ViewStaffViewPHPCustom', params: {id: item.id} })
      }      
    }    
   
  }
</script>

<style>

</style>
